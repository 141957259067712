import React, { useContext, useEffect } from 'react';
import './darkmode.css';
import { ThemeContext } from '../../App';

const DarkMode = () => {
    const { theme, toggleTheme } = useContext(ThemeContext);
    
    return (
        <a className="darkmode show-darkmode" onClick={toggleTheme}>
            <i className={`uil ${theme === 'light' ? 'uil-moon' : 'uil-sun'} darkmode__icon`}></i>
        </a>
    )
}

export default DarkMode